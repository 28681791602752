<template>
  <div>
    <h1 class="text-h4">Manage Aircraft</h1>
    <v-divider class="my-2"/>
  </div>
</template>

<script>
export default {
  name: "AircraftPage"
}
</script>

<style scoped>

</style>